var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-main',[_c('el-form',{staticClass:"el-form-search",attrs:{"label-width":"120px"}},[_c('el-form-item',{attrs:{"label":"物品类别名称："}},[_c('el-input',{attrs:{"size":"small","placeholder":"请输入商品名称"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('el-form-item',{attrs:{"label":"是否上架："}},[_c('el-select',{attrs:{"size":"small"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_c('el-option',{attrs:{"label":"全部","value":""}}),_c('el-option',{attrs:{"label":"上架","value":1}}),_c('el-option',{attrs:{"label":"下架","value":0}})],1)],1),_c('el-form-item',{attrs:{"label":" ","label-width":"25px"}},[_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.search}},[_vm._v("搜索")]),_c('el-button',{attrs:{"size":"small","type":"text"},on:{"click":_vm.searchClear}},[_vm._v("清除搜索条件")])],1)],1),_c('el-table',{attrs:{"data":_vm.list,"header-cell-style":{ 'background-color': '#F8F9FA' },"row-key":"id"}},[_c('template',{slot:"empty"},[_c('No')],1),_c('el-table-column',{attrs:{"width":"75px"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('el-checkbox',{on:{"change":_vm.selectToatl},model:{value:(_vm.allchecked),callback:function ($$v) {_vm.allchecked=$$v},expression:"allchecked"}},[_vm._v("全选")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-checkbox',{on:{"change":_vm.selectChange},model:{value:(row.checked),callback:function ($$v) {_vm.$set(row, "checked", $$v)},expression:"row.checked"}})]}}])}),_c('el-table-column',{attrs:{"width":"120","align":"center"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"edit"},[_c('span',[_vm._v("排序")]),_c('i',{staticClass:"el-icon-edit-outline"})])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"type":"number","min":0,"size":"small"},on:{"change":function($event){return _vm.editSort(row)}},model:{value:(row.sort),callback:function ($$v) {_vm.$set(row, "sort", $$v)},expression:"row.sort"}})]}}])}),_c('el-table-column',{attrs:{"prop":"id","label":"品类ID","align":"center"}}),_c('el-table-column',{attrs:{"prop":"picture","label":"品类主图","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-image',{staticStyle:{"width":"100px","height":"70px"},attrs:{"src":row.picture}})]}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"物品类别","align":"center"}}),_c('el-table-column',{attrs:{"prop":"status","label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status)?_c('el-button',{attrs:{"type":"info","size":"small"},on:{"click":function($event){return _vm.upper(row)}}},[_vm._v("下架")]):_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.upper(row)}}},[_vm._v("上架")])]}}])}),_c('el-table-column',{attrs:{"prop":"money","label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.edit(row)}}},[_vm._v("编辑")]),_c('el-button',{attrs:{"size":"small","type":"text"},on:{"click":function($event){return _vm.del(row)}}},[_vm._v("删除")])]}}])})],2),_c('Paging',{attrs:{"total":_vm.total_number,"page":_vm.page,"pageNum":_vm.rows},on:{"updatePageNum":_vm.updateData}},[_c('div',{attrs:{"slot":"batch"},slot:"batch"},[_c('el-checkbox',{staticStyle:{"margin-right":"20px"},on:{"change":_vm.selectToatl},model:{value:(_vm.allchecked),callback:function ($$v) {_vm.allchecked=$$v},expression:"allchecked"}},[_vm._v("全选")]),_c('el-select',{staticStyle:{"margin-right":"20px"},attrs:{"placeholder":"批量操作","size":"mini"},model:{value:(_vm.batchActionId),callback:function ($$v) {_vm.batchActionId=$$v},expression:"batchActionId"}},[_c('el-option',{attrs:{"label":"上架","value":1}}),_c('el-option',{attrs:{"label":"下架","value":0}})],1),_c('el-button',{attrs:{"size":"mini"},on:{"click":_vm.handleBatchAction}},[_vm._v("确定")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }