<template>
  <el-main>
    <el-form class="el-form-search" label-width="120px">
      <el-form-item label="物品类别名称：">
        <el-input size="small" v-model="name" placeholder="请输入商品名称"></el-input>
      </el-form-item>
      <el-form-item label="是否上架：">
        <el-select size="small" v-model="status">
          <el-option label="全部" value=""></el-option>
          <el-option label="上架" :value="1"></el-option>
          <el-option label="下架" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" @click="search" type="primary">搜索</el-button>
        <el-button size="small" @click="searchClear" type="text">清除搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column width="75px">
        <template #header>
          <el-checkbox v-model="allchecked" @change="selectToatl">全选</el-checkbox>
        </template>
        <template v-slot="{ row }">
          <el-checkbox v-model="row.checked" @change="selectChange"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column width="120" align="center">
        <template #header>
          <div class="edit">
            <span>排序</span>
            <i class="el-icon-edit-outline"></i>
          </div>
        </template>
        <template v-slot="{ row }">
          <el-input type="number" :min="0" size="small" v-model="row.sort" @change="editSort(row)"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="品类ID" align="center"></el-table-column>
      <el-table-column prop="picture" label="品类主图" align="center">
        <template v-slot="{ row }">
          <el-image style="width: 100px; height: 70px" :src="row.picture"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="物品类别" align="center"></el-table-column>
      <el-table-column prop="status" label="操作" align="center">
        <template v-slot="{ row }">
          <el-button v-if="row.status" @click="upper(row)" type="info" size="small">下架</el-button>
          <el-button v-else @click="upper(row)" size="small" type="primary">上架</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="money" label="操作" align="center">
        <template v-slot="{ row }">
          <el-button @click="edit(row)" size="small" type="text">编辑</el-button>
          <el-button @click="del(row)" size="small" type="text">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="page" :pageNum="rows" @updatePageNum="updateData">
      <div slot="batch">
        <el-checkbox v-model="allchecked" @change="selectToatl" style="margin-right: 20px">全选</el-checkbox>
        <el-select placeholder="批量操作" size="mini" v-model="batchActionId" style="margin-right: 20px">
          <el-option label="上架" :value="1"></el-option>
          <el-option label="下架" :value="0"></el-option>
        </el-select>
        <el-button size="mini" @click="handleBatchAction">确定</el-button>
      </div>
    </Paging>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      page: 1,
      rows: 10,
      name: '',
      status: '',
      list: [],
      total_number: 0,
      allchecked: !1,
      batchActionId: '',
    };
  },
  created() {
    this.getList();
  },
  methods: {
    search() {
      this.page = 1;
      this.rows = 10;
      this.getList();
    },
    searchClear(){
      this.name = '';
      this.status = '';
      this.page = 1;
      this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getList();
    },
    getList() {
      this.$axios
        .post(this.$api.O2O.recycleList, {
          page: this.page,
          rows: this.rows,
          name: this.name,
          status: this.status,
        })
        .then(res => {
          if (res.code == 0) {
            let list = res.result.list;
            list.map(item => {
              item.checked = !1;
            });
            this.list = list;
            this.total_number = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    selectToatl(val) {
      if (val) {
        for (let i in this.list) this.list[i].checked = !0;
      } else {
        for (let i in this.list) this.list[i].checked = !1;
      }
    },
    selectChange() {
      let checked = this.list.filter(item => item.checked);
      if (!checked.length) this.allchecked = !1;
    },
    editSort(row) {
      this.$axios
        .post(this.$api.O2O.recycleEditSort, {
          id: row.id,
          sort: row.sort,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('编辑成功');
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    edit(row) {
      this.$router.push({
        path: '/category/editCategory',
        query: {
          id: row.id,
        },
      });
    },
    upper(row) {
      this.$axios
        .post(this.$api.O2O.editStatus, {
          id: row.id,
          status: row.status ? 0 : 1,
        })
        .then(res => {
          if (res.code == 0) {
            row.status = row.status ? 0 : 1;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    handleBatchAction() {
      let list = this.list;
      let id = [];
      if (typeof this.batchActionId != 'number') return this.$message.warning('请选择批量操作类型');
      for (let i in list) {
        if (list[i].checked) id.push(list[i].id);
      }
      this.$axios
        .post(this.$api.O2O.editStatus, {
          status: this.batchActionId,
          id: id,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success(this.batchActionId ? '上架成功' : '下架成功');
            this.allchecked = !1;
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    del(row) {
      this.$confirm('确认删除此品类？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios
          .post(this.$api.O2O.delRecycle, {
            id: row.id,
          })
          .then(res => {
            if (res.code == 0) {
              this.$message.success('删除成功');
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .el-table {
    /deep/ .el-input__inner {
      border-color: #fff;
    }
    /deep/ .el-input__inner:focus {
      border-color: #409eff;
    }
  }
}
</style>
